import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";
import Input from "@material-ui/core/Input";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import IconButton from "@material-ui/core/IconButton";
import { Modal, Button } from "react-bootstrap";

const keys = require("../../config/key");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  header: {
    minHeight: "150px",
    width: "100%",
    padding: "10px",
    marginBottom: "30px",
  },
  headertitle: {
    marginLeft: "10px",
  },
  table: {
    marginTop: "20px",
  },
  formControl: {
    minWidth: 120,
    marginBottom: "20px",
  },
  searchButton: {
    marginTop: "10px",
  },
};

const useStyles = makeStyles(styles);

const initialUserState = {
  id: "",
  type: "",
  name: "",
  Username: "",
  description: "",
  isExternalCreator: false,
  image: "",
  cover: "",
};

const UserList = (props) => {
  //Material ui Table for words
  const classes = useStyles();
  //States for User list Tables
  const [error, setError] = useState("");
  const [users, setUsers] = React.useState([]);
  const [loader, setLoader] = React.useState(null);
  const [LastEvaluatedKey, setLastEvaluatedKey] = useState({});
  const [disable, setDisable] = useState(false);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [password, setPassword] = useState("");
  const [userData, setUserData] = useState(initialUserState);
  const [passwordError, setPasswordError] = useState("");
  const [modalValue, setModalValue] = useState("");

  const [downloadCount, setDownloadCount] = useState('');
  const [viewCount, setViewCount] = useState('');
  const [showBookCountModal, setBookCountModal] = useState(false);

  useEffect(() => {
    if (!props.auth.isAdmin) {
      props.history.push("/admin/Dashboard");
    }
  }, [props.auth]);

  useEffect(() => {
    setLoader(<CircularProgress color="secondary" />);
    fetchData();
  }, []);

  const fetchData = async () => {
    await axios.post(`/api/get-all-users`).then((res) => {
      if (res.data.error) {
        setError(res.data.error);
      } else {
        setError("");
        console.log("res.data", res.data);
        if (res.data.Items) {
          setUsers(res.data.Items);
        }
        if (res.data.LastEvaluatedKey) {
          setDisable(false);
          setLastEvaluatedKey(res.data.LastEvaluatedKey);
        } else {
          setDisable(true);
        }
      }
      setLoader(null);
    });
  };

  const loadMore = async (e) => {
    e.preventDefault();
    setLoader(<CircularProgress color="secondary" />);
    await axios.post(`/api/get-all-users`, { LastEvaluatedKey }).then((res) => {
      if (res.data.error) {
        setError(res.data.error);
      } else {
        setError("");
        console.log("res.data", res.data);
        if (res.data.Items) {
          setUsers([...users, ...res.data.Items]);
        }
        if (res.data.LastEvaluatedKey) {
          setLastEvaluatedKey(res.data.LastEvaluatedKey);
          setDisable(false);
        } else {
          setDisable(true);
        }
      }
      setLoader(null);
    });
  };

  const handleSearch = async (event) => {
    if (event.target.value !== "") {
      event.preventDefault();
      setLoader(<CircularProgress color="secondary" />);
      const searchValue = event.target.value;
      await axios
        .post("/api/search-dynamo-creators", { value: event.target.value })
        .then((res) => {
          if (res.data.error) {
            setError(res.data.error);
          } else {
            setError("");
            if (res.data.Items) {
              setUsers(res.data.Items);
              setSearch(searchValue);
            }
            if (res.data.LastEvaluatedKey) {
              setLastEvaluatedKey(res.data.LastEvaluatedKey);
              setDisable(false);
            } else {
              setDisable(true);
            }
          }
          setLoader(null);
        });
    } else {
      setLoader(<CircularProgress color="secondary" />);
      await axios.post(`/api/get-all-users`).then((res) => {
        if (res.data.error) {
          setError(res.data.error);
        } else {
          setError("");
          console.log("res.data", res.data);
          if (res.data.Items) {
            setUsers(res.data.Items);
          }
          if (res.data.LastEvaluatedKey) {
            setDisable(false);
            setLastEvaluatedKey(res.data.LastEvaluatedKey);
          } else {
            setDisable(true);
          }
        }
        setLoader(null);
      });
    }
  };

  const handleDeleteUser = async () => {
    document.getElementById("confirm").disabled = true;
    if (password === keys.passwordDeleteUser) {
      setPasswordError("");
      await axios
        .post("/api/auth/delete-user", { userId: userData.id })
        .then((res) => {
          if (res.data.success) {
            setUserData(initialUserState);
            setShow(false);
            setPassword("");
            props.history.push("/admin/user");
          } else {
            alert("Something went wrong when deleting user");
            console.log(res.data.error);
            document.getElementById("confirm").disabled = false;
          }
        })
        .catch((err) => {
          alert("Something went wrong when deleting user");
          console.log(err);
          document.getElementById("confirm").disabled = false;
        });
    } else {
      setPasswordError("Password is wrong");
      document.getElementById("confirm").disabled = false;
      setPassword("");
    }
  };

  const valueChange = (event) => {
    document.getElementById("lead").style.display = "block";
    let val = event.target.value;
    setModalValue(val);
    if (val == "confirm") {
      document.getElementById("confirm").disabled = false;
    } else {
      document.getElementById("confirm").disabled = true;
    }
  };


  const handleModifycount = (userId) => {

    let item = {};
    item.id = userId;

    if (downloadCount != '' && downloadCount > 0) {
      item.downloadCount = parseInt(downloadCount);
    }
    if (viewCount != '' && viewCount > 0) {
      item.viewCount = parseInt(viewCount);
    }

    if (item.downloadCount != undefined || item.viewCount != undefined) {
  
      axios.post(`/api/update-count-of-user`, item).then((res) => {
        if (res.data.error) {
          setError(res.data.error);
        } else {
          setError("");
          console.log("res.data", res.data);
          
          if(res.data.success){
            document.getElementById("search-bar").value = "";
            setSearch("");
            setDownloadCount('');
            setViewCount('');
            setBookCountModal(false);
            fetchData();
          }
        }
      });
    }

  }

  return (
    <div>
      <div className="text-center">
        <p style={{ color: "red" }}>{error}</p>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={5}>
          {loader}
        </GridItem>
        <GridItem xs={12} sm={12} md={7}>
          <Input
            id="search-bar"
            placeholder="Search Users"
            onChange={handleSearch}
            name="query"
          />
        </GridItem>
      </GridContainer>
      <table className="table" style={{ marginTop: "15px" }}>
        <thead className="thead-dark">
          <tr>
            <th scope="col">Image</th>
            <th scope="col">Cover</th>
            <th scope="col">Username</th>
            <th scope="col">Name</th>
            <th scope="col">Description</th>
            <th scope="col">External Creator</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((item, idx) => (
            <tr key={idx}>
              <td>
                {item.image !== undefined && item.image !== "" ? (
                  <img src={item.image} width="50" height="50" />
                ) : (
                  ""
                )}
              </td>
              <td>
                {item.cover !== undefined && item.cover !== "" ? (
                  <img src={item.cover} width="50" height="50" />
                ) : (
                  ""
                )}
              </td>
              <td>{item.Username}</td>
              <td>{item.name}</td>
              <td>
                {item.description !== undefined
                  ? item.description.length > 20
                    ? `${item.description.substring(0, 20)} ...`
                    : item.description
                  : ""}
              </td>
              <td>
                {item.isExternalCreator === true ? (
                  <DoneIcon style={{ color: "green" }} />
                ) : (
                  <ClearIcon style={{ color: "red" }} />
                )}
              </td>
              <td>
               <IconButton aria-label="edit">
                  <InfoIcon
                    onClick={(e) => {
                      setBookCountModal(true)
                      setUserData(item);
                    }}
                  />
                </IconButton>
                &nbsp; &nbsp;
                <IconButton aria-label="edit">
                  <EditIcon
                    onClick={(e) =>
                      (window.location.href = `/admin/users-edit-admin/${item.id}`)
                    }
                  />
                </IconButton>
                &nbsp; &nbsp;
                <IconButton aria-label="delete">
                  <DeleteIcon
                    onClick={(e) => {
                      setShow(true);
                      setUserData(item);
                      setModalValue("");
                      setPasswordError("");
                      setPassword("");
                    }}
                  />
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button
        disabled={disable}
        type="submit"
        className="btn btn-primary"
        onClick={loadMore}
      >
        Load More
      </button>
      <Modal
        show={show}
        onHide={() => {
          setUserData(initialUserState);
          setShow(false);
          setPassword("");
          setPasswordError("");
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm Delete User - {userData.Username}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="alert alert-warning" role="alert">
            Unexpected bad things will happen if you don't read this!
          </div>
          This action cannot be undone. This will permanently delete this user
          and delete all his uploaded books, categories and subcategories <br />
          <br />
          Please type{" "}
          <strong>
            <u>confirm</u>
          </strong>{" "}
          and enter the password to delete user's books, category, Subcategories
          and its courses permanently.
          <br />
          <br />
          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend"></div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <label>Confirm Delete</label>
                <input
                  type="text"
                  className="form-control"
                  value={modalValue}
                  onChange={valueChange}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <label>Enter password</label>
                <input
                  type="password"
                  className="form-control"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setPasswordError("");
                  }}
                />
              </GridItem>
            </GridContainer>
            <p style={{ fontSize: "10px", color: "red" }}>
              {passwordError.length > 0 ? passwordError : ""}
            </p>
          </div>
          <p id="lead" style={{ width: "100%", display: "none" }}>
            <button
              className="btn btn-danger btn-sm"
              id="confirm"
              onClick={handleDeleteUser}
              href="#"
              role="button"
              style={{ width: "100%" }}
            >
              I understand the consequences, delete this user
            </button>
          </p>
        </Modal.Body>
      </Modal>

{/* View Book Count modal */}

      <Modal
        show={showBookCountModal}
        onHide={() => {
          setBookCountModal(false);
          setUserData(initialUserState);
          setDownloadCount('');
          setViewCount('');
        }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            View/Modify Counts For User - {userData.Username}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="alert alert-warning" role="alert">
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <label><strong>Download Count :</strong></label>
                {userData.downloadCount}
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <label><strong>View Count :</strong></label>
                {userData.viewCount}
              </GridItem>
            </GridContainer>
          </div>

          <div className="input-group input-group-sm mb-3">
            <div className="input-group-prepend"></div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Download"
                  value={downloadCount}
                  onChange={(e) => {
                    setDownloadCount(e.target.value);
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <input
                  type="number"
                  className="form-control"
                  placeholder="View"
                  value={viewCount}
                  onChange={(e) => {
                    setViewCount(e.target.value);
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <button
                  className="btn btn-danger"
                  id="confirm"
                  onClick={handleModifycount.bind(this, userData.id)}
                  href="#"
                  role="button"
                  style={{ width: "100%" }}
                >
                  Modify
                </button>
              </GridItem>
            </GridContainer>
            <p style={{ fontSize: "10px", color: "red" }}>
              {passwordError.length > 0 ? passwordError : ""}
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(UserList);
