import React, { useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios"

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Input from "@material-ui/core/Input";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
// Icons
import EditIcon from "@material-ui/icons/EditOutlined";
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";
import RevertIcon from "@material-ui/icons/NotInterestedOutlined";

const keys = require("../../config/key");

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  selectTableCell: {
    width: 10
  },
  tableCell: {
    width: 90,
    height: 40
  },
  input: {
    width: 500,
    height: 40
  },
  editButton: {
    color: "blue"
  },
  saveButton: {
    color: "green"
  },
  cancelButton: {
    color: "red"
  }
}));

const createData = (slno, id, explore_id) => ({ slno, id, explore_id, isEditMode: false });

const CustomTableCell = ({ row, id, onChange }) => {

  const classes = useStyles();

  const { isEditMode } = row;

  return (
    <TableCell align="left" className={classes.tableCell}>
      {isEditMode ? (
        <Input
          value={row['id']}
          name={id}
          onChange={e => onChange(e, row)}
          className={classes.input}
        />
      ) : (
        row["id"]
      )}
    </TableCell>
  );
};


const TopSelling = (props) => {

  console.log('TopSelling Props', props);

  const [rows, setRows] = React.useState([]);
  const [previous, setPrevious] = React.useState({});
  const classes = useStyles();


  const GetExploreData = () => {

    axios.post("/api/getExploreData", { exploretype: 'top_selling' })
      .then(res => {

        console.log('GetExploreData res', res.data);

        createDummyData(25,res.data);

      })
      .catch(err => {
        console.log(err)
      });

  }


  const SaveBookID = (slno) => {

    const id = rows.find((_) => _.slno == slno).id;
    const explore_id = rows.find((_) => _.slno == slno).explore_id;

    if (id != undefined && id != '') {
      axios.post("/api/SaveExploreData",
        {
          id: id,
          serialno: slno,
          exploretype: 'top_selling',
          explore_id: explore_id
        })
        .then(res => {

          console.log('res', res)
        })
        .catch(err => {
          console.log(err)
        });
    }

    onToggleEditMode(slno);
  }

  const onToggleEditMode = (slno) => {
    setRows(state => {
      return rows.map(row => {
        if (row.slno === slno) {
          return { ...row, isEditMode: !row.isEditMode };
        }
        return row;
      });
    });
  };

  const onChange = (e, row) => {

    if (!previous[row.slno]) {
      setPrevious(state => ({ ...state, [row.slno]: row }));
    }

    const value = e.target.value;

    const { slno } = row;

    const newRows = rows.map(row => {
      if (row.slno === slno) {
        return { ...row, id: value };
      }
      return row;
    });

    setRows(newRows);
  };


  const onRevert = (slno) => {

    const newRows = rows.map(row => {
      if (row.slno === slno) {
        return previous[slno] ? previous[slno] : row;
      }
      return row;
    });

    setRows(newRows);

    setPrevious(state => {
      delete state[slno];
      return state;
    });

    onToggleEditMode(slno);
  };

  useEffect(() => {

    if (!props.auth.isAdmin) {

      props.history.push("/admin/user-new-dashboard");
    }
    else {

      GetExploreData();
    }

  }, [props.auth])

  const createDummyData = (number,exploreData) => {
    console.log('exploreData',exploreData);
    var data = []

    for (var i = 0; i < number; i++) {

      if (exploreData.length > 0) {

        const explore = exploreData.find((_) => _.serialno == (i + 1));

        if (explore != undefined) {
          data.push(createData((i + 1), explore.id, explore.explore_id));
        }
        else {
          data.push(createData((i + 1), '', ''));
        }

      }
      else
        data.push(createData((i + 1), '', ''));

    }

    setRows(data);
  }


  return (
    <Paper className={classes.root}>
      <Table className={classes.table} aria-label="caption table">

        <TableHead>
          <TableRow>

            <TableCell align="left">#</TableCell>
            <TableCell align="left">Book ID</TableCell>
            <TableCell align="left">Actions</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.slno}>

              <TableCell className={classes.selectTableCell} align="left">{row.slno}</TableCell>
              <CustomTableCell {...{ row, id: "slno", onChange }} />
              <TableCell className={classes.selectTableCell}>
                {row.isEditMode ? (
                  <>
                    <IconButton
                      className={classes.saveButton}
                      aria-label="done"
                      onClick={() => SaveBookID(row.slno)}
                    >
                      <DoneIcon />
                    </IconButton>
                    <IconButton
                      className={classes.cancelButton}
                      aria-label="revert"
                      onClick={() => onRevert(row.slno)}
                    >
                      <RevertIcon />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    className={classes.editButton}
                    aria-label="delete"
                    onClick={() => onToggleEditMode(row.slno)}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps)(TopSelling);