import React, { useState, useEffect } from "react";
// react plugin for creating charts
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import CategoryCard from "components/Card/CategoryCard.js";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import propTypes from "prop-types";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { NavLink } from "react-router-dom";
//Icons
import LibraryBooksSharpIcon from "@material-ui/icons/LibraryBooksSharp";
import CategorySharpIcon from "@material-ui/icons/CategorySharp";
import GroupSharpIcon from "@material-ui/icons/GroupSharp";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
//Style
import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
import { connect } from "react-redux";
import {
  Getstats,
  GetMonthlyBookCount,
  GetUserUploads,
  GetMonthlyFileCount,
  GetPremiumMonthlyFileCount,
  GetWeeklyBookCount,
  GetWeeklyFileCount,
  GetPremiumWeeklyFileCount,
  GetUserWeeklyFileUploads,
  GetUserPremiumWeeklyFileUploads,
  GetUserMonthlybookUploads,
  GetUserMonthlyFileUploads,
  GetUserPremiumMonthlyFileUploads,
  GetUserWeeklyBookUploads,
  GetUserPremiumUploads,
  GetUserPremiumMonthlybookUploads,
  GetUserPremiumWeeklyBookUploads,
  GetUserMonthlyDownload,
  GetUserWeeklyDownload,
  GetUserMonthlyPurchased,
  GetUserWeeklyPurchased,
} from "../../actions/Books.js";
import LinearProgress from "@material-ui/core/LinearProgress";
//Graph library
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Button } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
const axios = require("axios");

const Dashboard = (props) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  console.log(props);

  const [loading_1, setLoading_1] = React.useState(true);
  const [loading_2, setLoading_2] = React.useState(true);
  const [loading_3, setLoading_3] = React.useState(true);
  const [loading_4, setLoading_4] = React.useState(true);
  const [loading_5, setLoading_5] = React.useState(true);
  const [loading_6, setLoading_6] = React.useState(true);
  const [loading_7, setLoading_7] = React.useState(true);
  const [loading_8, setLoading_8] = React.useState(true);
  const [loading_9, setLoading_9] = React.useState(true);
  const [loading_10, setLoading_10] = React.useState(true);
  const [loading_11, setLoading_11] = React.useState(true);
  const [loading_12, setLoading_12] = React.useState(true);
  const [loading_13, setLoading_13] = React.useState(true);
  const [loading_14, setLoading_14] = React.useState(true);
  const [loading_15, setLoading_15] = React.useState(true);
  const [loading_16, setLoading_16] = React.useState(true);
  const [loading_17, setLoading_17] = React.useState(true);
  const [loading_18, setLoading_18] = React.useState(true);
  const [loading_19, setLoading_19] = React.useState(true);

  const [usercount, setUsercount] = React.useState(0);
  const [categorycount, setCategorycount] = React.useState(0);
  const [bookcount, setBookcount] = React.useState(0);
  const [time, setTime] = React.useState(0);
  const [files, setFileCount] = React.useState(0);
  const [ugcBookCount, setugcBookCount] = React.useState(0);
  const [mobileBookCount, setmobileBookCount] = React.useState(0);
  const [ugcXdata, setugcXData] = useState([]);
  const [ugcYdata, setugcYData] = useState([]);
  const [mobileXdata, setmobileXData] = useState([]);
  const [mobileYdata, setmobileYData] = useState([]);
  //radio iput
  const [value, setValue] = React.useState("Month");
  const [ugcValue, setugcValue] = React.useState("Month");
  const [mobileValue, setmobileValue] = React.useState("Month");
  const [filegraphtype, setFilegraphtype] = React.useState("Month");
  const [filepremiumgraphtype, setFilepremiumgraphtype] = React.useState(
    "Month"
  );

  //Points for monthly book count graph
  const [data, setData] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [publishedbooks, setPublishedBooks] = React.useState([]);

  //Points for monthly files count graph
  const [filedata, setFileData] = React.useState([]);
  const [filecategories, setFileCategories] = React.useState([]);
  const [premiumfiledata, setPremiumFileData] = React.useState([]);
  const [premiumfilecategories, setPremiumFileCategories] = React.useState([]);

  //Points for User uploads
  const [uploaddata, setUploaddata] = React.useState([]);
  const [uploadcategories, setUploadcategories] = React.useState([]);

  //Points for user premium uploads
  const [uploadPremiumdata, setUploadPremiumdata] = React.useState([]);
  const [uploadPremiumcategories, setUploadPremiumcategories] = React.useState(
    []
  );

  //points for User monthly book uploads
  const [umbdata, setUmbdata] = React.useState([]);
  const [umbcategories, setUmbcategories] = React.useState([]);

  //points for User weekly book uploads
  const [uwbdata, setUwbdata] = React.useState([]);
  const [uwbcategories, setUwbcategories] = React.useState([]);

  //points for User monthly book uploads
  const [umbpdata, setUmbpdata] = React.useState([]);
  const [umbpcategories, setUmbpcategories] = React.useState([]);

  //points for User weekly book uploads
  const [uwbpdata, setUwbpdata] = React.useState([]);
  const [uwbpcategories, setUwbpcategories] = React.useState([]);

  //points for User monthly files uploads
  const [umfdata, setUmfdata] = React.useState([]);
  const [umfcategories, setUmfcategories] = React.useState([]);

  const [umfpdata, setUmfpdata] = React.useState([]);
  const [umfpcategories, setUmfpcategories] = React.useState([]);

  //points for User monthly files uploads
  const [uwfdata, setUwfdata] = React.useState([]);
  const [uwfcategories, setUwfcategories] = React.useState([]);

  const [uwfpdata, setUwfpdata] = React.useState([]);
  const [uwfpcategories, setUwfpcategories] = React.useState([]);

  const [bweek, setBweek] = React.useState([]);
  const [fweek, setFweek] = React.useState([]);

  const [start, setStart] = React.useState({
    year: 2020,
    month: "may",
    monthnum: 1,
  });

  const [today, setToday] = React.useState({
    month: new Date().toLocaleString("default", { month: "short" }),
    year: new Date().getFullYear(),
    monthnum: new Date().getMonth() + 1,
    week: getWeekOfMonth(),
  });

  //Validity till 2025
  function getWeekOfMonth() {
    var date = new Date();
    const startWeekDayIndex = 1; // 1 MonthDay 0 Sundays
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstDay = firstDate.getDay();

    let weekNumber = Math.ceil((date.getDate() + firstDay) / 7);
    if (startWeekDayIndex === 1) {
      if (date.getDay() === 0 && date.getDate() > 1) {
        weekNumber -= 1;
      }

      if (firstDate.getDate() === 1 && firstDay === 0 && date.getDate() > 1) {
        weekNumber += 1;
      }
    }
    return weekNumber;
  }

  //Set months of different graph
  const [u_m_b_month, setUMBMonth] = React.useState([]);
  const [u_m_f_month, setUMFMonth] = React.useState([]);

  //Set year of different graph
  const [u_m_b_year, setUMBYear] = React.useState([]);
  const [u_m_f_year, setUMFYear] = React.useState([]);

  const [show_months_books, setSMB] = React.useState([]);
  const [show_months_files, setSMF] = React.useState([]);

  const [show_years_books, setSYB] = React.useState([]);
  const [show_years_files, setSYF] = React.useState([]);

  const [user_monthly_downoadsX, setUserMonthlyDownloadsX] = React.useState([]);
  const [user_monthly_downoadsY, setUserMonthlyDownloadsY] = React.useState([]);

  const [user_weekly_downloadsX, setUserWeeklyDownloadsX] = React.useState([]);
  const [user_weekly_downloadsY, setUserWeeklyDownloadsY] = React.useState([]);

  const [user_monthly_purchansedX, setUserMonthlyPurchasedX] = React.useState(
    []
  );
  const [user_monthly_purchansedY, setUserMonthlyPurchasedY] = React.useState(
    []
  );

  const [user_weekly_purchansedX, setUserWeeklyPurchasedX] = React.useState([]);
  const [user_weekly_purchansedY, setUserWeeklyPurchasedY] = React.useState([]);

  const selectUserBookMonths = (Year) => {
    console.log("Reached here");
    console.log(Year);
    const months = [
      { name: "January", value: 1 },
      { name: "February", value: 2 },
      { name: "March", value: 3 },
      { name: "April", value: 4 },
      { name: "May", value: 5 },
      { name: "June", value: 6 },
      { name: "July", value: 7 },
      { name: "August", value: 8 },
      { name: "September", value: 9 },
      { name: "October", value: 10 },
      { name: "Novemeber", value: 11 },
      { name: "December", value: 12 },
    ];

    //Check the year selected by the user

    if (Year === today.year) {
      console.log("Matched Year");
      //If current year is selected show months only till current month
      var shows = [];
      var current = today.monthnum;
      console.log(today.monthnum);
      for (var i = 0; i < current; i++) {
        shows.push(months[i]);
      }
      setSMB(shows);
      console.log(shows);
    } else {
      console.log("Previous Year");
      //If previous month is selected select all months
      setSMB(months);
      console.log(months);
    }
  };

  const selectUserFilesMonths = (Year) => {
    const months = [
      { name: "January", value: 1 },
      { name: "February", value: 2 },
      { name: "March", value: 3 },
      { name: "April", value: 4 },
      { name: "May", value: 5 },
      { name: "June", value: 6 },
      { name: "July", value: 7 },
      { name: "August", value: 8 },
      { name: "September", value: 9 },
      { name: "October", value: 10 },
      { name: "Novemeber", value: 11 },
      { name: "December", value: 12 },
    ];

    //Check the year selected by the user

    if (Year === today.year) {
      //If current year is selected show months only till current month
      var shows = [];
      var current = today.monthnum;

      for (var i = 0; i < current; i++) {
        shows.push(months[i]);
      }
      setSMF(shows);
    } else {
      //If previous month is selected select all months
      setSMF(months);
      console.log(months);
    }
  };

  useEffect(() => {
    var years = [];
    for (var current = start.year; current <= today.year; current++) {
      years.push(current);
    }
    setSYB(years);
    setSYF(years);
    console.log(today.year);
    selectUserFilesMonths(today.year);
    selectUserBookMonths(today.year);
    setUMBMonth(today.monthnum);
    setUMBYear(today.year);
    setUMFMonth(today.monthnum);
    setUMFYear(today.year);
    setBweek(today.week);
    setFweek(today.week);
    props.GetUserPremiumMonthlybookUploads(today);
    props.GetUserPremiumWeeklyBookUploads(today);
    props.GetUserMonthlybookUploads(today);
    props.GetUserMonthlyDownload(today);
    props.GetUserWeeklyDownload(today);
    props.GetUserMonthlyPurchased(today);
    props.GetUserWeeklyPurchased(today);
    props.GetUserMonthlyFileUploads(today);
    props.GetUserPremiumMonthlyFileUploads(today);
    props.GetUserWeeklyFileUploads(today);
    props.GetUserPremiumWeeklyFileUploads(today);
    props.GetUserWeeklyBookUploads(today);
    fetchData();
  }, [props.auth]);

  const fetchData = async () => {
    await axios.get(`/api/getMonthly/ugcBookCount`).then((res) => {
      setugcXData(res.data.x);
      setugcYData(res.data.y);
    });
    await axios.get(`/api/getMonthly/mobileBookCount`).then((res) => {
      setmobileXData(res.data.x);
      setmobileYData(res.data.y);
    });
  };

  const changeBYear = (event) => {
    setUMBYear(event.target.value);
    var item = {};
    item.year = event.target.value;
    item.month = u_m_b_month;
    console.log(item);
    selectUserBookMonths(event.target.value);
    props.GetUserMonthlybookUploads(item);
    props.GetUserPremiumMonthlybookUploads(item);
    item.week = bweek;
    props.GetUserWeeklyBookUploads(item);
    props.GetUserPremiumWeeklyBookUploads(item);
    props.GetUserMonthlyDownload(item);
    props.GetUserWeeklyDownload(item);
    props.GetUserMonthlyPurchased(item);
    props.GetUserWeeklyPurchased(item);
  };

  const changeFYear = (event) => {
    setUMFYear(event.target.value);
    var item = {};
    item.year = event.target.value;
    item.month = u_m_f_month;
    console.log(item);
    selectUserFilesMonths(event.target.value);
    props.GetUserMonthlyFileUploads(item);
    props.GetUserPremiumMonthlyFileUploads(item);
    item.week = fweek;
    props.GetUserWeeklyFileUploads(item);
    props.GetUserPremiumWeeklyFileUploads(item);
  };

  const changeFPYear = (event) => {
    setUMFYear(event.target.value);
    var item = {};
    item.year = event.target.value;
    item.month = u_m_f_month;
    console.log(item);
    selectUserFilesMonths(event.target.value);
    props.GetUserMonthlyFileUploads(item);
    props.GetUserPremiumMonthlyFileUploads(item);
    item.week = fweek;
    props.GetUserWeeklyFileUploads(item);
    props.GetUserPremiumWeeklyFileUploads(item);
    props.GetUserMonthlyPurchased(item);
    props.GetUserMonthlyDownload(item);
  };

  const changeBMonth = (event) => {
    setUMBMonth(event.target.value);
    var item = {};
    item.monthnum = event.target.value;
    item.year = u_m_b_year;
    console.log(item);
    props.GetUserMonthlybookUploads(item);
    props.GetUserPremiumMonthlybookUploads(item);
    item.week = bweek;
    props.GetUserWeeklyBookUploads(item);
    props.GetUserPremiumWeeklyBookUploads(item);
    props.GetUserMonthlyDownload(item);
    props.GetUserWeeklyDownload(item);
    props.GetUserMonthlyPurchased(item);
    props.GetUserWeeklyPurchased(item);
  };

  const changeFMonth = (event) => {
    setUMFMonth(event.target.value);
    var item = {};
    item.monthnum = event.target.value;
    item.year = u_m_f_year;
    console.log(item);
    //Update weekly file counter
    props.GetUserMonthlyFileUploads(item);

    item.week = fweek;
    props.GetUserWeeklyFileUploads(item);
  };

  const changeFPMonth = (event) => {
    setUMFMonth(event.target.value);
    var item = {};
    item.monthnum = event.target.value;
    item.year = u_m_f_year;
    console.log(item);
    //Update weekly file counter
    props.GetUserPremiumMonthlyFileUploads(item);

    item.week = fweek;
    props.GetUserPremiumWeeklyFileUploads(item);
    props.GetUserMonthlyPurchased(item);
    props.GetUserMonthlyDownload(item);
  };

  useEffect(() => {
    props.Getstats();
    props.GetMonthlyBookCount();
    props.GetUserUploads();
    props.GetUserPremiumUploads();
    props.GetMonthlyFileCount();
    props.GetPremiumMonthlyFileCount();
    console.log(today.month);
  }, [props.auth]);

  useEffect(() => {
    const { stats } = props.stats;
    console.log(stats);
    stats.map((key) => {
      setTime(key.Date);
      setUsercount(key.UserCount);
      setCategorycount(key.CategoryCount);
      setBookcount(key.BookCount);
      setFileCount(key.filesCount);
      setugcBookCount(key.ugcCount);
      setmobileBookCount(key.mobileCount);
    });
  }, [props.stats]);

  //Listen for monthly book count
  useEffect(() => {
    let array = [];
    setCategories(props.monthlybooks.data.x);
    var Count = 0;
    array = props.monthlybooks.data.y;
    if (array) {
      array.map((count) => {
        Count = Count + count;
      });
    }
    setPublishedBooks(Count);
    setData(props.monthlybooks.data.y);
  }, [props.monthlybooks]);

  //Listen for monthly files count
  useEffect(() => {
    setFileData(props.filescount.data.y);
    setFileCategories(props.filescount.data.x);
    setPremiumFileData(props.filescount.premiumdata.y);
    setPremiumFileCategories(props.filescount.premiumdata.x);
  }, [props.filescount]);

  //Listen for User uploads
  useEffect(() => {
    setUploadcategories(props.uploads.data.x);
    setUploaddata(props.uploads.data.y);
    if (props.uploads.data2) {
      setUploadPremiumcategories(props.uploads.data2.x);
      setUploadPremiumdata(props.uploads.data2.y);
    }
    if (props.uploads.monthlybookcounts) {
      setUmbcategories(props.uploads.monthlybookcounts.x);
      setUmbdata(props.uploads.monthlybookcounts.y);
    }
    if (props.uploads.premiummonthlybookcounts) {
      setUmbpcategories(props.uploads.premiummonthlybookcounts.x);
      setUmbpdata(props.uploads.premiummonthlybookcounts.y);
    }
    if (props.uploads.monthlyfilecounts) {
      console.log(props.uploads.monthlyfilecounts);
      setUmfcategories(props.uploads.monthlyfilecounts.x);
      setUmfdata(props.uploads.monthlyfilecounts.y);
    }
    if (props.uploads.premiummonthlyfilecounts) {
      console.log(props.uploads.premiummonthlyfilecounts);
      setUmfpcategories(props.uploads.premiummonthlyfilecounts.x);
      setUmfpdata(props.uploads.premiummonthlyfilecounts.y);
    }

    if (props.uploads.weeklybookcounts) {
      console.log(props.uploads.weeklybookcounts);
      setUwbdata(props.uploads.weeklybookcounts.y);
      setUwbcategories(props.uploads.weeklybookcounts.x);
    }
    if (props.uploads.premiumweeklybookcounts) {
      console.log(props.uploads.premiumweeklybookcounts);
      setUwbpdata(props.uploads.premiumweeklybookcounts.y);
      setUwbpcategories(props.uploads.premiumweeklybookcounts.x);
    }

    if (props.uploads.weeklyfilecounts) {
      setUwfcategories(props.uploads.weeklyfilecounts.x);
      setUwfdata(props.uploads.weeklyfilecounts.y);
    }
    if (props.uploads.premiumweeklyfilecounts) {
      setUwfpcategories(props.uploads.premiumweeklyfilecounts.x);
      setUwfpdata(props.uploads.premiumweeklyfilecounts.y);
    }
    if (props.uploads.monthly_download) {
      setUserMonthlyDownloadsY(props.uploads.monthly_download.y);
      setUserMonthlyDownloadsX(props.uploads.monthly_download.x);
    }
    if (props.uploads.weekly_download) {
      setUserWeeklyDownloadsY(props.uploads.weekly_download.y);
      setUserWeeklyDownloadsX(props.uploads.weekly_download.x);
    }
    if (props.uploads.monthly_purchased) {
      setUserMonthlyPurchasedY(props.uploads.monthly_purchased.y);
      setUserMonthlyPurchasedX(props.uploads.monthly_purchased.x);
    }
    if (props.uploads.weekly_purchased) {
      setUserWeeklyPurchasedY(props.uploads.weekly_purchased.y);
      setUserWeeklyPurchasedX(props.uploads.weekly_purchased.x);
    }
  }, [props.uploads]);

  //Handel Radio change

  const handleRadioChange = (event) => {
    setValue(event.target.value);
    if (event.target.value === "Week") {
      props.GetWeeklyBookCount();
    } else {
      props.GetMonthlyBookCount();
    }
  };

  const handleRadioChangeUgc = (event) => {
    setugcValue(event.target.value);
    if (event.target.value === "Week") {
      axios.get(`/api/getWeekly/ugcBookCount`).then((res) => {
        setugcXData(res.data.x);
        setugcYData(res.data.y);
      });
    } else {
      axios.get(`/api/getMonthly/ugcBookCount`).then((res) => {
        setugcXData(res.data.x);
        setugcYData(res.data.y);
      });
    }
  };

  const handleRadioChangeMobile = (event) => {
    setmobileValue(event.target.value);
    if (event.target.value === "Week") {
      axios.get(`/api/getWeekly/mobileBookCount`).then((res) => {
        setmobileXData(res.data.x);
        setmobileYData(res.data.y);
      });
    } else {
      axios.get(`/api/getMonthly/mobileBookCount`).then((res) => {
        setmobileXData(res.data.x);
        setmobileYData(res.data.y);
      });
    }
  };

  //Handel Radio change

  const handleBookWeekChange = (event) => {
    console.log(event.target.value);
    setBweek(event.target.value);
    var items = {};
    items.week = event.target.value;
    items.monthnum = u_m_b_month;
    items.year = u_m_b_year;
    console.log(items);
    props.GetUserWeeklyBookUploads(items);
    props.GetUserPremiumWeeklyBookUploads(items);
  };

  const handleFileWeekChange = (event) => {
    console.log(event.target.value);
    setFweek(event.target.value);
    var items = {};
    items.week = event.target.value;
    items.monthnum = u_m_f_month;
    items.year = u_m_f_year;
    props.GetUserWeeklyFileUploads(items);
  };
  const handlePremiumFileWeekChange = (event) => {
    console.log(event.target.value);
    setFweek(event.target.value);
    var items = {};
    items.week = event.target.value;
    items.monthnum = u_m_f_month;
    items.year = u_m_f_year;
    props.GetUserPremiumWeeklyFileUploads(items);
    props.GetUserWeeklyPurchased(items);
    props.GetUserWeeklyDownload(items);
  };

  const handleFileRadioChange = (event) => {
    setFilegraphtype(event.target.value);
    if (event.target.value === "Week") {
      props.GetWeeklyFileCount();
      // props.GetPremiumWeeklyFileCount();
    } else {
      props.GetMonthlyFileCount();
      // props.GetPremiumMonthlyFileCount();
    }
  };

  const handlePremiumFileRadioChange = (event) => {
    setFilepremiumgraphtype(event.target.value);

    if (event.target.value === "Week") {
      // props.GetWeeklyFileCount();
      props.GetPremiumWeeklyFileCount();
    } else {
      // props.GetMonthlyFileCount();
      props.GetPremiumMonthlyFileCount();
    }
  };

  const MonthlyBookoptions = {
    chart: {
      type: "line",
    },
    title: {
      text: "Monthly Added Books  Count",
    },
    xAxis: {
      categories: categories,
      title: { text: "Months" },
    },
    yAxis: {
      title: { text: "Books" },
    },
    series: [
      {
        color: "#059e8a",
        name: "Monthly Books Added",
        data: data,
      },
    ],
  };

  const MonthlyBookoptionsUGCBOOKS = {
    chart: {
      type: "line",
    },
    title: {
      text: "UGC Book Count",
    },
    xAxis: {
      categories: ugcXdata,
      title: { text: "Months" },
    },
    yAxis: {
      title: { text: "Books" },
    },
    series: [
      {
        color: "#059e8a",
        name: "Monthly UGC Books Added",
        data: ugcYdata,
      },
    ],
  };

  const MonthlyBookoptionsMobileBOOKS = {
    chart: {
      type: "line",
    },
    title: {
      text: "Mobile Book Count",
    },
    xAxis: {
      categories: mobileXdata,
      title: { text: "Months" },
    },
    yAxis: {
      title: { text: "Books" },
    },
    series: [
      {
        color: "#059e8a",
        name: "Monthly Mobile Books Added",
        data: mobileYdata,
      },
    ],
  };

  const MonthlyFilesoptions = {
    chart: {
      type: "line",
    },
    title: {
      text: "Monthly Added Files Count",
    },
    xAxis: {
      categories: filecategories,
      title: { text: "Months" },
    },
    yAxis: {
      title: { text: "Files" },
    },
    series: [
      {
        color: "#6f03fc",
        name: "Monthly total files added",
        data: filedata,
      },
    ],
  };
  const monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const PremiumMonthlyFilesoptions = {
    chart: {
      type: "line",
    },
    title: {
      text: "Premium Monthly Added Files Count",
    },
    xAxis: {
      categories: premiumfilecategories,
      title: { text: "Months" },
    },
    yAxis: {
      title: { text: "Files" },
    },
    series: [
      {
        color: "#6f03fc",
        name: "Premium Monthly total files added",
        data: premiumfiledata,
      },
    ],
  };

  const Useruploadsoptions = {
    chart: {
      type: "bar",
    },
    title: {
      text: "Total Free books uploaded by users",
    },
    xAxis: {
      categories: uploadcategories,
      title: { text: "Username" },
    },
    yAxis: {
      title: { text: "books" },
    },
    series: [{ color: "#ffa726", name: "Books Uploaded", data: uploaddata }],
  };

  const Userpremiumuploadsoptions = {
    chart: {
      type: "bar",
    },
    title: {
      text: "Total Premium books uploaded by users",
    },
    xAxis: {
      categories: uploadPremiumcategories,
      title: { text: "Username" },
    },
    yAxis: {
      title: { text: "books" },
    },
    series: [
      { color: "#ffa726", name: "Books Uploaded", data: uploadPremiumdata },
    ],
  };

  const UserBookuploadsMonthlyoptions = {
    chart: {
      type: "bar",
    },
    title: {
      text: "Monthly Free books uploaded by users",
    },
    xAxis: {
      categories: umbcategories,
      title: { text: "Username" },
    },
    yAxis: {
      title: { text: "books" },
    },
    series: [{ color: "#db48d9", name: "Books Uploaded", data: umbdata }],
  };

  const UserBookuploadsWeeklyoptions = {
    chart: {
      type: "bar",
    },
    title: {
      text: `${monthArray[u_m_b_month - 1] +
        " " +
        u_m_b_year} weekly Free book uploaded by users`,
    },
    xAxis: {
      categories: uwbcategories,
      title: { text: "Username" },
    },
    yAxis: {
      title: { text: "books" },
    },
    series: [{ color: "#23E129", name: "Books Uploaded", data: uwbdata }],
  };

  const UserBookuploadsPremiumMonthlyoptions = {
    chart: {
      type: "bar",
    },
    title: {
      text: "Monthly Premium books uploaded by users",
    },
    xAxis: {
      categories: umbpcategories,
      title: { text: "Username" },
    },
    yAxis: {
      title: { text: "books" },
    },
    series: [{ color: "#db48d9", name: "Books Uploaded", data: umbpdata }],
  };

  const UserBookuploadsPremiumWeeklyoptions = {
    chart: {
      type: "bar",
    },
    title: {
      text: `${monthArray[u_m_b_month - 1] +
        " " +
        u_m_b_year} weekly Premium book uploaded by users`,
    },
    xAxis: {
      categories: uwbpcategories,
      title: { text: "Username" },
    },
    yAxis: {
      title: { text: "books" },
    },
    series: [{ color: "#23E129", name: "Books Uploaded", data: uwbpdata }],
  };

  const UserFileuploadsMonthlyoptions = {
    chart: {
      type: "bar",
    },
    title: {
      text: "Monthly files uploaded by users",
    },
    xAxis: {
      categories: umfcategories,
      title: { text: "Username" },
    },
    yAxis: {
      title: { text: "books" },
    },
    series: [{ color: "#7C23E1", name: "Files Uploaded", data: umfdata }],
  };

  const UserPremiumFileuploadsMonthlyoptions = {
    chart: {
      type: "bar",
    },
    title: {
      text: "Premium Monthly files uploaded by users",
    },
    xAxis: {
      categories: umfpcategories,
      title: { text: "Username" },
    },
    yAxis: {
      title: { text: "books" },
    },
    series: [{ color: "#7C23E1", name: "Files Uploaded", data: umfpdata }],
  };

  const UserFileuploadsWeeklyoptions = {
    chart: {
      type: "bar",
    },
    title: {
      text: `${monthArray[u_m_f_month - 1] +
        " " +
        u_m_f_year}  weekly files uploaded by users`,
    },
    xAxis: {
      categories: uwfcategories,
      title: { text: "Username" },
    },
    yAxis: {
      title: { text: "books" },
    },
    series: [{ color: "#138CF6", name: "Files Uploaded", data: uwfdata }],
  };

  const UserPremiumFileuploadsWeeklyoptions = {
    chart: {
      type: "bar",
    },
    title: {
      text: `${monthArray[u_m_f_month - 1] +
        " " +
        u_m_f_year} Premium weekly files uploaded by users`,
    },
    xAxis: {
      categories: uwfpcategories,
      title: { text: "Username" },
    },
    yAxis: {
      title: { text: "books" },
    },
    series: [{ color: "#138CF6", name: "Files Uploaded", data: uwfpdata }],
  };
  const UserMonthlyDownload = {
    chart: {
      type: "bar",
    },
    title: {
      text: `Monthly Downloads`,
    },
    xAxis: {
      categories: user_monthly_downoadsX,
      title: { text: "Username" },
    },
    yAxis: {
      title: { text: "Downloads" },
    },
    series: [
      {
        color: "#138CF6",
        name: "Books Downloaded",
        data: user_monthly_downoadsY,
      },
    ],
  };

  const UserWeeklyDownload = {
    chart: {
      type: "bar",
    },
    title: {
      text: `Weekly Downloads`,
    },
    xAxis: {
      categories: user_weekly_downloadsX,
      title: { text: "Username" },
    },
    yAxis: {
      title: { text: "Downloads" },
    },
    series: [
      {
        color: "#138CF6",
        name: "Books Downloaded",
        data: user_weekly_downloadsY,
      },
    ],
  };

  const UserMonthlyPurchased = {
    chart: {
      type: "bar",
    },
    title: {
      text: `Monthly Purchased`,
    },
    xAxis: {
      categories: user_monthly_purchansedX,
      title: { text: "Username" },
    },
    yAxis: {
      title: { text: "Downloads" },
    },
    series: [
      {
        color: "#138CF6",
        name: "Books Purchased",
        data: user_monthly_purchansedY,
      },
    ],
  };

  const UserWeeklyPurchased = {
    chart: {
      type: "bar",
    },
    title: {
      text: `Weekly Purchased`,
    },
    xAxis: {
      categories: user_weekly_purchansedX,
      title: { text: "Username" },
    },
    yAxis: {
      title: { text: "Downloads" },
    },
    series: [
      {
        color: "#138CF6",
        name: "Books Purchased",
        data: user_weekly_purchansedY,
      },
    ],
  };

  const adminInfoCards = () => (
    <>
      <GridContainer>
        <GridItem xs={12} sm={6} md={1}></GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card
            onClick={(e) => {
              const win = window.open("/admin/table", "_blank");
              win.focus();
            }}
            style={{ cursor: "pointer" }}
          >
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <LibraryBooksSharpIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Books</p>
              <h3 className={classes.cardTitle}>{bookcount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>updated on {time}</div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card
            onClick={(e) => {
              const win = window.open("/admin/dashboard", "_blank");
              win.focus();
            }}
            style={{ cursor: "pointer" }}
          >
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <CategorySharpIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total categories</p>
              <h3 className={classes.cardTitle}>{categorycount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>updated on {time}</div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card
            onClick={(e) => {
              const win = window.open("/admin/list-users", "_blank");
              win.focus();
            }}
            style={{ cursor: "pointer" }}
          >
            <CardHeader color="success" stats icon>
              <CardIcon color="warning">
                <GroupSharpIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Users</p>
              <h3 className={classes.cardTitle}>{usercount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>updated on {time}</div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={1}></GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6} md={1}></GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <CloudUploadIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Files</p>
              <h3 className={classes.cardTitle}>{files}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>updated on {time}</div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card
            onClick={(e) => {
              const win = window.open(
                "admin/dashboard/55f8bc21-4286-449c-825f-de27e3475930/ff4e2c09-d26d-4237-87ad-4f77ed0f8d1c",
                "_blank"
              );
              win.focus();
            }}
            style={{ cursor: "pointer" }}
          >
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <LibraryBooksSharpIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total UGC Books</p>
              <h3 className={classes.cardTitle}>{ugcBookCount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>updated on {time}</div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <GroupSharpIcon />
              </CardIcon>
              <p className={classes.cardCategory}>Total Mobile Books</p>
              <h3 className={classes.cardTitle}>{mobileBookCount}</h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>updated on {time}</div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </>
  );

  const loadingGraph = (load, setLoad, title) => {
    if (load) {
      return (
        <div style={{ position: "relative", textAlign: "center" }}>
          <img
            src={
              "https://lambda-trigger-test-rishank.s3.ap-south-1.amazonaws.com/graph-test/test.PNG"
            }
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
              WebkitFilter: "blur(4px)",
              filter: "blur(4px)",
            }}
          />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <p style={{ fontSize: "18px" }}>{title}</p>
            <Button onClick={() => setLoad(false)}>Load Graph</Button>
          </div>
        </div>
      );
    } else {
      return "";
    }
  };

  return (
    <div>
      {adminInfoCards()}
      <GridContainer>
        {props.auth.isAdmin ? (
          <>
            {/* 1 */}
            <GridItem xs={12} sm={12} md={12}>
              <Paper style={{ padding: "10px" }} elevation={1}>
                {loadingGraph(
                  loading_1,
                  setLoading_1,
                  MonthlyBookoptions.title.text
                )}
                {!loading_1 && (
                  <>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="graphtype"
                      value={value}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel
                        value="Month"
                        control={<Radio />}
                        label="Monthly"
                      />
                      <FormControlLabel
                        value="Week"
                        control={<Radio />}
                        label="Weekly"
                      />
                    </RadioGroup>
                    <h4>Total Published Books : {publishedbooks}</h4>
                    <HighchartsReact
                      style={{ marginBottom: "20px" }}
                      highcharts={Highcharts}
                      options={MonthlyBookoptions}
                    />
                  </>
                )}
              </Paper>
              <br></br>
            </GridItem>
            {/* 2 */}
            <GridItem xs={12} sm={12} md={12}>
              <Paper style={{ padding: "10px" }} elevation={1}>
                {loadingGraph(
                  loading_2,
                  setLoading_2,
                  MonthlyBookoptionsUGCBOOKS.title.text
                )}
                {!loading_2 && (
                  <>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="graphtype"
                      value={ugcValue}
                      onChange={handleRadioChangeUgc}
                    >
                      <FormControlLabel
                        value="Month"
                        control={<Radio />}
                        label="Monthly"
                      />
                      <FormControlLabel
                        value="Week"
                        control={<Radio />}
                        label="Weekly"
                      />
                    </RadioGroup>
                    <h4>Total UGC Books : {ugcBookCount}</h4>
                    <HighchartsReact
                      style={{ marginBottom: "20px" }}
                      highcharts={Highcharts}
                      options={MonthlyBookoptionsUGCBOOKS}
                    />
                  </>
                )}
              </Paper>
              <br></br>
            </GridItem>
            {/* 3 */}
            <GridItem xs={12} sm={12} md={12}>
              <Paper style={{ padding: "10px" }} elevation={1}>
                {loadingGraph(
                  loading_3,
                  setLoading_3,
                  MonthlyBookoptionsMobileBOOKS.title.text
                )}
                {!loading_3 && (
                  <>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="graphtype"
                      value={mobileValue}
                      onChange={handleRadioChangeMobile}
                    >
                      <FormControlLabel
                        value="Month"
                        control={<Radio />}
                        label="Monthly"
                      />
                      <FormControlLabel
                        value="Week"
                        control={<Radio />}
                        label="Weekly"
                      />
                    </RadioGroup>
                    <h4>Total Mobile Books : {mobileBookCount}</h4>
                    <HighchartsReact
                      style={{ marginBottom: "20px" }}
                      highcharts={Highcharts}
                      options={MonthlyBookoptionsMobileBOOKS}
                    />
                  </>
                )}
              </Paper>
              <br></br>
            </GridItem>
            {/* 4 */}
            <GridItem xs={12} sm={12} md={12}>
              <Paper style={{ padding: "10px" }} elevation={1}>
                {loadingGraph(
                  loading_4,
                  setLoading_4,
                  MonthlyFilesoptions.title.text
                )}
                {!loading_4 && (
                  <>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="graphtype"
                      value={filegraphtype}
                      onChange={handleFileRadioChange}
                    >
                      <FormControlLabel
                        value="Month"
                        control={<Radio />}
                        label="Monthly"
                      />
                      <FormControlLabel
                        value="Week"
                        control={<Radio />}
                        label="Weekly"
                      />
                    </RadioGroup>
                    <h4>Total Files : {files}</h4>
                    <HighchartsReact
                      style={{ marginBottom: "20px" }}
                      highcharts={Highcharts}
                      options={MonthlyFilesoptions}
                    />
                  </>
                )}
              </Paper>
              <br></br>
            </GridItem>
            {/* 5 */}
            <GridItem xs={12} sm={12} md={12}>
              <Paper style={{ padding: "10px" }} elevation={1}>
                {loadingGraph(
                  loading_5,
                  setLoading_5,
                  PremiumMonthlyFilesoptions.title.text
                )}
                {!loading_5 && (
                  <>
                    <RadioGroup
                      row
                      aria-label="position"
                      name="graphtype"
                      value={filepremiumgraphtype}
                      onChange={handlePremiumFileRadioChange}
                    >
                      <FormControlLabel
                        value="Month"
                        control={<Radio />}
                        label="Monthly"
                      />
                      <FormControlLabel
                        value="Week"
                        control={<Radio />}
                        label="Weekly"
                      />
                    </RadioGroup>
                    <h4>Total Files : {files}</h4>
                    <HighchartsReact
                      style={{ marginBottom: "20px" }}
                      highcharts={Highcharts}
                      options={PremiumMonthlyFilesoptions}
                    />
                  </>
                )}
              </Paper>
              <br></br>
            </GridItem>
          </>
        ) : (
          <></>
        )}
        {/* 6 */}
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ padding: "10px" }} elevation={1}>
            {loadingGraph(
              loading_6,
              setLoading_6,
              Useruploadsoptions.title.text
            )}
            {!loading_6 && (
              <HighchartsReact
                style={{ marginBottom: "20px" }}
                highcharts={Highcharts}
                options={Useruploadsoptions}
              />
            )}
          </Paper>
          <br></br>
        </GridItem>

        {/* 7 */}
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ padding: "10px" }} elevation={1}>
            {loadingGraph(
              loading_7,
              setLoading_7,
              Userpremiumuploadsoptions.title.text
            )}
            {!loading_7 && (
              <>
                <HighchartsReact
                  style={{ marginBottom: "20px" }}
                  highcharts={Highcharts}
                  options={Userpremiumuploadsoptions}
                />
              </>
            )}
          </Paper>
          <br></br>
        </GridItem>

        {/* 8 */}
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ padding: "10px" }} elevation={1}>
            {loadingGraph(
              loading_8,
              setLoading_8,
              UserBookuploadsMonthlyoptions.title.text
            )}
            {!loading_8 && (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={2}>
                    <FormControl
                      varient="filled"
                      className={classes.formControl}
                    >
                      <InputLabel id="Month">Month</InputLabel>
                      <Select
                        labelId="Month"
                        id="select-month"
                        defaultValue={today.monthnum}
                        onChange={changeBMonth}
                      >
                        {show_months_books.map((month) => (
                          <MenuItem value={month.value}>{month.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={4} md={2}>
                    <FormControl
                      varient="filled"
                      className={classes.formControl}
                    >
                      <InputLabel id="Year">Year</InputLabel>
                      <Select
                        labelId="Year"
                        id="select-year"
                        defaultValue={today.year}
                        onChange={changeBYear}
                      >
                        {show_years_books.map((year) => (
                          <MenuItem value={year}>{year}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <HighchartsReact
                  style={{ marginBottom: "20px" }}
                  highcharts={Highcharts}
                  options={UserBookuploadsMonthlyoptions}
                />
              </>
            )}
          </Paper>
          <br></br>
        </GridItem>

        {/* 9 */}
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ padding: "10px" }} elevation={1}>
            {loadingGraph(
              loading_9,
              setLoading_9,
              UserBookuploadsWeeklyoptions.title.text
            )}
            {!loading_9 && (
              <>
                <GridItem xs={12} sm={4} md={2}>
                  <FormControl varient="filled" className={classes.formControl}>
                    <InputLabel id="BWeek">Week</InputLabel>
                    <Select
                      labelId="BWeek"
                      id="select-Bweek"
                      defaultValue={today.week}
                      onChange={handleBookWeekChange}
                    >
                      <MenuItem value={1}>Week 1</MenuItem>
                      <MenuItem value={2}>Week 2</MenuItem>
                      <MenuItem value={3}>Week 3</MenuItem>
                      <MenuItem value={4}>Week 4</MenuItem>
                      <MenuItem value={5}>Week 5</MenuItem>
                      <MenuItem value={6}>Week 6</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <HighchartsReact
                  style={{ marginBottom: "20px" }}
                  highcharts={Highcharts}
                  options={UserBookuploadsWeeklyoptions}
                />
              </>
            )}
          </Paper>
          <br></br>
        </GridItem>

        {/* 10 */}
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ padding: "10px" }} elevation={1}>
            {loadingGraph(
              loading_10,
              setLoading_10,
              UserBookuploadsPremiumMonthlyoptions.title.text
            )}
            {!loading_10 && (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={2}>
                    <FormControl
                      varient="filled"
                      className={classes.formControl}
                    >
                      <InputLabel id="Month">Month</InputLabel>
                      <Select
                        labelId="Month"
                        id="select-month"
                        defaultValue={today.monthnum}
                        onChange={changeBMonth}
                      >
                        {show_months_books.map((month) => (
                          <MenuItem value={month.value}>{month.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={4} md={2}>
                    <FormControl
                      varient="filled"
                      className={classes.formControl}
                    >
                      <InputLabel id="Year">Year</InputLabel>
                      <Select
                        labelId="Year"
                        id="select-year"
                        defaultValue={today.year}
                        onChange={changeBYear}
                      >
                        {show_years_books.map((year) => (
                          <MenuItem value={year}>{year}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <HighchartsReact
                  style={{ marginBottom: "20px" }}
                  highcharts={Highcharts}
                  options={UserBookuploadsPremiumMonthlyoptions}
                />
              </>
            )}
          </Paper>
          <br></br>
        </GridItem>

        {/* 11 */}
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ padding: "10px" }} elevation={1}>
            {loadingGraph(
              loading_11,
              setLoading_11,
              UserBookuploadsPremiumWeeklyoptions.title.text
            )}
            {!loading_11 && (
              <>
                <GridItem xs={12} sm={4} md={2}>
                  <FormControl varient="filled" className={classes.formControl}>
                    <InputLabel id="BWeek">Week</InputLabel>
                    <Select
                      labelId="BWeek"
                      id="select-Bweek"
                      defaultValue={today.week}
                      onChange={handleBookWeekChange}
                    >
                      <MenuItem value={1}>Week 1</MenuItem>
                      <MenuItem value={2}>Week 2</MenuItem>
                      <MenuItem value={3}>Week 3</MenuItem>
                      <MenuItem value={4}>Week 4</MenuItem>
                      <MenuItem value={5}>Week 5</MenuItem>
                      <MenuItem value={6}>Week 6</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <HighchartsReact
                  style={{ marginBottom: "20px" }}
                  highcharts={Highcharts}
                  options={UserBookuploadsPremiumWeeklyoptions}
                />
              </>
            )}
          </Paper>
          <br></br>
        </GridItem>

        {/* 12 */}
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ padding: "10px" }} elevation={1}>
            {loadingGraph(
              loading_12,
              setLoading_12,
              UserFileuploadsMonthlyoptions.title.text
            )}
            {!loading_12 && (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={2}>
                    <FormControl
                      varient="filled"
                      className={classes.formControl}
                    >
                      <InputLabel id="Month">Month</InputLabel>
                      <Select
                        labelId="Month"
                        id="select-month"
                        defaultValue={today.monthnum}
                        onChange={changeFMonth}
                      >
                        {show_months_files.map((month) => (
                          <MenuItem value={month.value}>{month.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={4} md={2}>
                    <FormControl
                      varient="filled"
                      className={classes.formControl}
                    >
                      <InputLabel id="Year">Year</InputLabel>
                      <Select
                        labelId="Year"
                        id="select-year"
                        defaultValue={today.year}
                        onChange={changeFYear}
                      >
                        {show_years_files.map((year) => (
                          <MenuItem value={year}>{year}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <HighchartsReact
                  style={{ marginBottom: "20px" }}
                  highcharts={Highcharts}
                  options={UserFileuploadsMonthlyoptions}
                />
              </>
            )}
          </Paper>
          <br></br>
        </GridItem>

        {/* 13 */}
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ padding: "10px" }} elevation={1}>
            {loadingGraph(
              loading_13,
              setLoading_13,
              UserPremiumFileuploadsMonthlyoptions.title.text
            )}
            {!loading_13 && (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={2}>
                    <FormControl
                      varient="filled"
                      className={classes.formControl}
                    >
                      <InputLabel id="Month">Month</InputLabel>
                      <Select
                        labelId="Month"
                        id="select-month"
                        defaultValue={today.monthnum}
                        onChange={changeFPMonth}
                      >
                        {show_months_files.map((month) => (
                          <MenuItem value={month.value}>{month.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={4} md={2}>
                    <FormControl
                      varient="filled"
                      className={classes.formControl}
                    >
                      <InputLabel id="Year">Year</InputLabel>
                      <Select
                        labelId="Year"
                        id="select-year"
                        defaultValue={today.year}
                        onChange={changeFPYear}
                      >
                        {show_years_files.map((year) => (
                          <MenuItem value={year}>{year}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <HighchartsReact
                  style={{ marginBottom: "20px" }}
                  highcharts={Highcharts}
                  options={UserPremiumFileuploadsMonthlyoptions}
                />
              </>
            )}
          </Paper>
          <br></br>
        </GridItem>

        {/* 14 */}
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ padding: "10px" }} elevation={1}>
            {loadingGraph(
              loading_14,
              setLoading_14,
              UserFileuploadsWeeklyoptions.title.text
            )}
            {!loading_14 && (
              <>
                <GridItem xs={12} sm={4} md={2}>
                  <FormControl varient="filled" className={classes.formControl}>
                    <InputLabel id="Month">Week</InputLabel>
                    <Select
                      labelId="Week"
                      id="select-week"
                      defaultValue={today.week}
                      onChange={handleFileWeekChange}
                    >
                      <MenuItem value={1}>Week 1</MenuItem>
                      <MenuItem value={2}>Week 2</MenuItem>
                      <MenuItem value={3}>Week 3</MenuItem>
                      <MenuItem value={4}>Week 4</MenuItem>
                      <MenuItem value={5}>Week 5</MenuItem>
                      <MenuItem value={6}>Week 6</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <HighchartsReact
                  style={{ marginBottom: "20px" }}
                  highcharts={Highcharts}
                  options={UserFileuploadsWeeklyoptions}
                />
              </>
            )}
          </Paper>
          <br></br>
        </GridItem>

        {/* 15 */}
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ padding: "10px" }} elevation={1}>
            {loadingGraph(
              loading_15,
              setLoading_15,
              UserPremiumFileuploadsWeeklyoptions.title.text
            )}
            {!loading_15 && (
              <>
                <GridItem xs={12} sm={4} md={2}>
                  <FormControl varient="filled" className={classes.formControl}>
                    <InputLabel id="Month">Week</InputLabel>
                    <Select
                      labelId="Week"
                      id="select-week"
                      defaultValue={today.week}
                      onChange={handlePremiumFileWeekChange}
                    >
                      <MenuItem value={1}>Week 1</MenuItem>
                      <MenuItem value={2}>Week 2</MenuItem>
                      <MenuItem value={3}>Week 3</MenuItem>
                      <MenuItem value={4}>Week 4</MenuItem>
                      <MenuItem value={5}>Week 5</MenuItem>
                      <MenuItem value={6}>Week 6</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <HighchartsReact
                  style={{ marginBottom: "20px" }}
                  highcharts={Highcharts}
                  options={UserPremiumFileuploadsWeeklyoptions}
                />
              </>
            )}
          </Paper>
          <br></br>
        </GridItem>

        {/* 16 */}
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ padding: "10px" }} elevation={1}>
            {loadingGraph(
              loading_16,
              setLoading_16,
              UserMonthlyDownload.title.text
            )}
            {!loading_16 && (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={2}>
                    <FormControl
                      varient="filled"
                      className={classes.formControl}
                    >
                      <InputLabel id="Month">Month</InputLabel>
                      <Select
                        labelId="Month"
                        id="select-month"
                        defaultValue={today.monthnum}
                        onChange={changeFPMonth}
                      >
                        {show_months_files.map((month) => (
                          <MenuItem value={month.value}>{month.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={4} md={2}>
                    <FormControl
                      varient="filled"
                      className={classes.formControl}
                    >
                      <InputLabel id="Year">Year</InputLabel>
                      <Select
                        labelId="Year"
                        id="select-year"
                        defaultValue={today.year}
                        onChange={changeFPYear}
                      >
                        {show_years_files.map((year) => (
                          <MenuItem value={year}>{year}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <HighchartsReact
                  style={{ marginBottom: "20px" }}
                  highcharts={Highcharts}
                  options={UserMonthlyDownload}
                />
              </>
            )}
          </Paper>
          <br></br>
        </GridItem>

        {/* 17 */}
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ padding: "10px" }} elevation={1}>
            {loadingGraph(
              loading_17,
              setLoading_17,
              UserWeeklyDownload.title.text
            )}
            {!loading_17 && (
              <>
                <GridItem xs={12} sm={4} md={2}>
                  <FormControl varient="filled" className={classes.formControl}>
                    <InputLabel id="Month">Week</InputLabel>
                    <Select
                      labelId="Week"
                      id="select-week"
                      defaultValue={today.week}
                      onChange={handlePremiumFileWeekChange}
                    >
                      <MenuItem value={1}>Week 1</MenuItem>
                      <MenuItem value={2}>Week 2</MenuItem>
                      <MenuItem value={3}>Week 3</MenuItem>
                      <MenuItem value={4}>Week 4</MenuItem>
                      <MenuItem value={5}>Week 5</MenuItem>
                      <MenuItem value={6}>Week 6</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <HighchartsReact
                  style={{ marginBottom: "20px" }}
                  highcharts={Highcharts}
                  options={UserWeeklyDownload}
                />
              </>
            )}
          </Paper>
          <br></br>
        </GridItem>

        {/* 18 */}
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ padding: "10px" }} elevation={1}>
            {loadingGraph(
              loading_18,
              setLoading_18,
              UserMonthlyPurchased.title.text
            )}
            {!loading_18 && (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={2}>
                    <FormControl
                      varient="filled"
                      className={classes.formControl}
                    >
                      <InputLabel id="Month">Month</InputLabel>
                      <Select
                        labelId="Month"
                        id="select-month"
                        defaultValue={today.monthnum}
                        onChange={changeFPMonth}
                      >
                        {show_months_files.map((month) => (
                          <MenuItem value={month.value}>{month.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>

                  <GridItem xs={12} sm={4} md={2}>
                    <FormControl
                      varient="filled"
                      className={classes.formControl}
                    >
                      <InputLabel id="Year">Year</InputLabel>
                      <Select
                        labelId="Year"
                        id="select-year"
                        defaultValue={today.year}
                        onChange={changeFPYear}
                      >
                        {show_years_files.map((year) => (
                          <MenuItem value={year}>{year}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <HighchartsReact
                  style={{ marginBottom: "20px" }}
                  highcharts={Highcharts}
                  options={UserMonthlyPurchased}
                />
              </>
            )}
          </Paper>
          <br></br>
        </GridItem>

        {/* 19 */}
        <GridItem xs={12} sm={12} md={12}>
          <Paper style={{ padding: "10px" }} elevation={1}>
            {loadingGraph(
              loading_19,
              setLoading_19,
              UserWeeklyPurchased.title.text
            )}
            {!loading_19 && (
              <>
                <GridItem xs={12} sm={4} md={2}>
                  <FormControl varient="filled" className={classes.formControl}>
                    <InputLabel id="Month">Week</InputLabel>
                    <Select
                      labelId="Week"
                      id="select-week"
                      defaultValue={today.week}
                      onChange={handlePremiumFileWeekChange}
                    >
                      <MenuItem value={1}>Week 1</MenuItem>
                      <MenuItem value={2}>Week 2</MenuItem>
                      <MenuItem value={3}>Week 3</MenuItem>
                      <MenuItem value={4}>Week 4</MenuItem>
                      <MenuItem value={5}>Week 5</MenuItem>
                      <MenuItem value={6}>Week 6</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <HighchartsReact
                  style={{ marginBottom: "20px" }}
                  highcharts={Highcharts}
                  options={UserWeeklyPurchased}
                />
              </>
            )}
          </Paper>
          <br></br>
        </GridItem>
      </GridContainer>
    </div>
  );
};
Dashboard.propTypes = {
  Getstats: propTypes.func.isRequired,
  GetMonthlyBookCount: propTypes.func.isRequired,
  GetUserUploads: propTypes.func.isRequired,
  GetUserPremiumUploads: propTypes.func.isRequired,
  GetUserPremiumMonthlybookUploads: propTypes.func.isRequired,
  GetUserPremiumWeeklyBookUploads: propTypes.func.isRequired,
  GetMonthlyFileCount: propTypes.func.isRequired,
  GetPremiumMonthlyFileCount: propTypes.func.isRequired,
  GetWeeklyBookCount: propTypes.func.isRequired,
  GetUserMonthlyDownload: propTypes.func.isRequired,
  GetUserWeeklyDownload: propTypes.func.isRequired,
  GetUserMonthlyPurchased: propTypes.func.isRequired,
  GetUserWeeklyPurchased: propTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  items: state.items,
  stats: state.stats,
  monthlybooks: state.monthlybooks,
  uploads: state.uploads,
  filescount: state.filescount,
  downloads: state.downloads,
});

export default connect(
  mapStateToProps,
  {
    Getstats,
    GetMonthlyBookCount,
    GetUserUploads,
    GetUserPremiumUploads,
    GetMonthlyFileCount,
    GetPremiumMonthlyFileCount,
    GetWeeklyBookCount,
    GetWeeklyFileCount,
    GetPremiumWeeklyFileCount,
    GetUserWeeklyFileUploads,
    GetUserPremiumWeeklyFileUploads,
    GetUserMonthlybookUploads,
    GetUserMonthlyDownload,
    GetUserWeeklyDownload,
    GetUserMonthlyPurchased,
    GetUserWeeklyPurchased,
    GetUserMonthlyFileUploads,
    GetUserPremiumMonthlyFileUploads,
    GetUserWeeklyBookUploads,
    GetUserPremiumMonthlybookUploads,
    GetUserPremiumWeeklyBookUploads,
  }
)(Dashboard);
