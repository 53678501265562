import React, { useEffect } from "react";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { TablePagination } from "@material-ui/core";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { GetBookReports } from "../../actions/Books";
import { CircularProgress } from "@material-ui/core";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import Eye from "@material-ui/icons/Visibility";
import BookReview from "@material-ui/icons/LinkTwoTone";
import DateConverter from "DateConverter/dateconverter";
import { Modal } from "react-bootstrap";
import Tooltip from '@material-ui/core/Tooltip';

const axios = require("axios");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  header: {
    minHeight: "150px",
    width: "100%",
    padding: "10px",
    marginBottom: "30px",
  },
  headertitle: {
    marginLeft: "10px",
  },
  table: {
    marginTop: "20px",
  },
  formControl: {
    minWidth: 120,
    marginBottom: "20px",
  },
  searchButton: {
    marginTop: "10px",
  },
};

const useStyles = makeStyles(styles);

const initialBookReportState = {
  bookName: "",
  id: "",
  message: "",
  parent_id: "",
  reportType: "",
  timestamp: 1634630771522,
  type: "BookReport",
  uploader_id: "",
  userEmail: "",
  userName: "",
  userPhone: "",
};

const BookReports = (props) => {
  const classes = useStyles();
  const [arr, setArr] = React.useState([]);
  const [index, setIndex] = React.useState(-1);
  const [disabled, setNext] = React.useState(true);
  const [prevItems, setPrev] = React.useState([]);
  const [flag, setFlag] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [page, setPage] = React.useState(-1);
  const [loader, setLoader] = React.useState(null);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [book, setBook] = React.useState(initialBookReportState);

  const [state, setState] = React.useState({
    columns: [
      { title: "Book Name", field: "bookName" },
      { title: "UserName", field: "userName" },
      { title: "Reported On", field: "timestamp" },
      { title: "Report Type", field: "reportType" },
      { title: "Message", field: "message_short" },
    ],
  });

  useEffect(() => {
    props.GetBookReports({});
    setLoader(<CircularProgress color="secondary" />);
    setFlag(true);
  }, [props.auth]);

  //Function to listen to new paginated data
  useEffect(() => {
    if (flag) {
      //Push the next key in array
      setIndex(index + 1);
      setArr([...arr, props.bookReports.Key]);
      prevItems.push.apply(prevItems, props.bookReports.Books);
      props.bookReports.Books.map((data) => {
        data.timestamp = DateConverter(data.timestamp);
        data["message_short"] =
          data.message !== undefined
            ? data.message.length > 25
              ? `${data.message.substring(0, 25)}...`
              : data.message
            : "";
      });
      setLoader(null);
      setData(prevItems);
    }
  }, [props.bookReports]);

  // Load more Button to store previous state data and query for next paginated data
  const loadmore = (page) => {
    console.log(`page = ${page}`);
    console.log(`index = ${index}`);
    if (page > index && arr[index]) {
      setLoader(<CircularProgress color="secondary" />);
      var lastIndexKey = arr[index];
      setPrev(data);
      // Pass evaluated key and state contained dates
      props.GetBookReports(lastIndexKey);
    }
  };

  // Logic to disable load more button when no key is recieved
  useEffect(() => {
    console.log("Execute props.Key effect", props.bookReports.Key);
    if (props.bookReports.Key) {
      setNext(false);
    } else {
      setNext(true);
    }
  }, [props.bookReports.Key]);

  const showReport = (event, rowData) => {
    setBook(rowData);
    setModalIsOpen(true);
  };

  const archiveReport = (bookData) => {
    axios
      .post("/api/archive/report", { reportId: bookData.id })
      .then((res) => {
        setBook(initialBookReportState);
        setModalIsOpen(false);
        if (res.data.success) {
          reset();
          props.GetBookReports({});
        } else {
          alert("Something went wrong");
        }
      })
      .catch((err) => {
        alert("Something went wrong");
        console.log(err);
      });
  };

  const reset = () => {
    setData([]);
    setArr([]);
    setPrev([]);
    setIndex(-1);
  };

  const actionArchiveReport = (event, rowData) => {
    archiveReport(rowData);
  };

  const showBook = (event, rowData) => {
    const win = window.open(
      "/admin/ViewBook/Book/" + rowData.parent_id.split("Report:")[1],
      "_blank"
    );
    win.focus();
  };

  return (
    <div>
      {loader}
      <MaterialTable
        title="Book Reports"
        className={classes.table}
        columns={state.columns}
        data={data.map((x) => Object.assign({}, x))}
        options={{
          pageSize: 10,
        }}
        actions={[
          {
            icon: () => <Tooltip title="View Report" placement="bottom"><Eye/></Tooltip>,
            onClick: showReport,
          },
          {
            icon: () => <Tooltip title="Mark Report as reviewed" placement="bottom"><RemoveCircleIcon/></Tooltip>,
            onClick: actionArchiveReport,
          },
          {
            icon: () => <Tooltip title="View Book Reported" placement="bottom"><BookReview/></Tooltip>,
            onClick: showBook,
          },
        ]}
        icons={
          ({ LastPage: () => <div /> },
          {
            FirstPage: () => <div />,
          })
        }
        onChangePage={(page) => {
          console.log(page);
          setPage(page);
          loadmore(page);
        }}
        components={{
          Pagination: (props) => (
            <TablePagination
              {...props}
              rowsPerPage={10}
              rowsPerPageOptions={[10]}
            />
          ),
        }}
      />
      <Modal
        keyboard={false}
        show={modalIsOpen}
        onHide={() => {
          setModalIsOpen(false);
          setBook(initialBookReportState);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">{book.bookName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            <u>Reported</u> <u>By</u> : {book.userName}
          </span>
          <br />
          <span>
            <u>User</u> <u>Email</u> : {book.userEmail}
          </span>
          <br />
          <span>
            {" "}
            <u>PhoneNumber</u> : {book.userPhone}
          </span>
          <br />
          <span>
            {" "}
            <u>Reported</u> <u>On</u> : {book.timestamp}
          </span>
          <br />
          <span>
            {" "}
            <u>Reported</u> <u>Type</u> : {book.reportType}
          </span>
          <br />
          <span>
            {" "}
            <u>Message</u> : {book.message}{" "}
          </span>
          <br />
          <br />
          <p id="lead" style={{ width: "100%" }}>
            <button
              class="btn btn-danger btn-sm"
              id="confirm"
              onClick={() => archiveReport(book)}
              href="#"
              role="button"
              style={{ width: "100%" }}
            >
              Mark as reviewed
            </button>
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};
BookReports.propTypes = {
  GetBookReports: propTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
  bookReports: state.bookReports,
});

export default connect(
  mapStateToProps,
  { GetBookReports }
)(BookReports);
