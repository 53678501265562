import React, { useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios"
import { makeStyles } from "@material-ui/core/styles";

import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import FileInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "components/CustomButtons/Button.js";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import DoneIcon from "@material-ui/icons/DoneAllTwoTone";

import { useForm, ErrorMessage, Controller } from "react-hook-form";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    imageList: {
        width: '100%',
        padding : 10,
        transform: 'translateZ(0)',
    },
    title :{
        color : 'black',
        fontSize : 18,
        fontWeight : 'bold'
    },
    titleBar: {
        background: 'linear-gradient(to bottom, rgb(255 255 255 / 0%) 0%, rgb(0 0 0 / 0%) 70%, rgb(0 0 0 / 0%) 100%)'
    },
    activeicon: {
        color: 'black',
    },
    inactieicon: {
        color: 'white',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
}));

const keys = require("../../config/key");

const BannerUpload = (props) => {
    const classes = useStyles();
    const { handleSubmit, errors, control, setValue } = useForm();

    const [banner, setBanner] = React.useState(null);
    const [bannerData, setBannerData] = React.useState([]);

    const GetExploreData = () => {

        axios.post("/api/getExploreData", { exploretype: 'banner' })
            .then(res => {

                console.log('GetExploreData res', res.data);

                setBannerData(res.data);

            })
            .catch(err => {
                console.log(err)
            });

    }

    const onImageClick = (explore_id) => {

        console.log(explore_id)

        if (!bannerData.find((_) => _.explore_id == explore_id && _.isactive)) {

            axios.post("/api/UpdateBannerStatus",
                {
                    explore_id: explore_id,
                    isactive: true,
                    bannerData : bannerData
                })
                .then(res => {
                    console.log('res', res);
                    GetExploreData();
                })
                .catch(err => {
                    console.log(err)
                });
        }
    }

    const HandleFileInput = event => {
        event.preventDefault();
        setBanner(event.target.files[0]);
    }

    const onSubmit = () => {
        if (banner === null || banner === "no image") {
            alert("Image is required");
        }
        else {

            let formData = new FormData();
            formData.append('selectedImage', banner);

            axios.post("/upload/BannerImageUpload", formData, {
                headers: {
                    'accept': 'application/json',
                    'Accept-Language': 'en-US,en;q=0.8',
                    'Content-Type': `multipart/form-data`,
                }
            })
                .then((response) => {
                    console.log('BannerImageUpload', response);

                    axios.post("/api/SaveExploreBanner",
                        {
                            exploretype: 'banner',
                            bannerpath: response.data.location
                        })
                        .then(res => {
                            console.log('res', res)

                            GetExploreData();
                            setBanner(null);
                        })
                        .catch(err => {
                            console.log(err)
                        });
                }).catch((error) => {
                    console.log(error)
                });
        }
    }

    useEffect(() => {

        if (!props.auth.isAdmin) {

            props.history.push("/admin/user-new-dashboard");
        }
        else {
            GetExploreData();
        }

    }, [props.auth])


    return (
        <>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <h3 className={classes.cardCategoryWhite}>Upload Banner</h3>
                        </CardHeader>
                        <CardBody>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <FileInput
                                        id="image-file"
                                        type="file"
                                        control={control}
                                        name="ImageFile"
                                        onChange={HandleFileInput}

                                        formControlProps={{
                                            fullWidth: true
                                        }}
                                    />
                                    <FormHelperText>Banner size should be less than 2 mb</FormHelperText>
                                </GridItem>
                            </GridContainer>
                        </CardBody>
                        <CardFooter>
                            <Button type="submit" color="rose" onClick={onSubmit}>Save</Button>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>

            <div className={classes.root}>
                <ImageList rowHeight={100} gap={5} className={classes.imageList}>
                    {bannerData.map((item) => (
                        <ImageListItem key={item.explore_id} cols={item.featured ? 2 : 1} rows={item.featured ? 2 : 1}>
                            <img src={item.bannerpath} alt="banner image" />
                            <ImageListItemBar
                                title={item.isactive ? 'Selected' : ''}
                                classes={{
                                    title: classes.title,
                                  }}
                                position="top"
                                actionIcon={
                                    <IconButton className={ item.isactive ? classes.activeicon : classes.inactiveicon} onClick={() => onImageClick(item.explore_id)}>
                                        <DoneIcon />
                                    </IconButton>
                                }
                                actionPosition="left"
                                className={classes.titleBar}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(mapStateToProps)(BannerUpload);