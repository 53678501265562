import axios from "axios";
import { GET_ALL_PURCHASE_DETAILS, CLEAR_PURCHASES, GET_ERRORS, GET_ALL_PUBLISHER_DETAILS } from "./types";

export const GetAllPurchases = () => dispatch => {

    //dispatch(clearPurchases())

    axios
        .get("/api/all-purchases")
        .then(res => {
            dispatch({
                type: GET_ALL_PURCHASE_DETAILS,
                payload: res.data
            })
            console.log('rrrrrrrrrrrrrrrrrr', res)
        })
        .catch(err => {
            if (err.response) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data
                })

            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: err
                })
            }
        }
        );
}

export const GetAllPublishers = () => dispatch => {

    axios
        .get("/api/get-all-publishers")
        .then(res => {
            dispatch({
                type: GET_ALL_PUBLISHER_DETAILS,
                payload: res.data
            })
        })
        .catch(err => {
            if (err.response) {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data
                })

            } else {
                dispatch({
                    type: GET_ERRORS,
                    payload: err
                })
            }
        }
        );
}

export const clearPurchases = () => dispatch => {
    dispatch({
        type: CLEAR_PURCHASES
    })
}