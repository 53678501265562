import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/test.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useForm, ErrorMessage, Controller } from "react-hook-form";
import { connect } from "react-redux";
import { registerUser, changePassword } from "../../actions/authAction";
import {
  clearMybooks,
  clearCategories,
  clearSubcategories,
  clearSubbooks,
} from "../../actions/Books.js";
import propTypes from "prop-types";
import { Typography } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FileInput from "components/CustomInput/CustomInput.js";
const axios = require("axios");

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },

  form: {},
};

const useStyles = makeStyles(styles);

const EditUserAdmin = (props) => {
  const [error, setError] = React.useState("");
  const [success, setSuccess] = React.useState(false);
  const [name, setName] = useState("");
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [youtube, setYoutube] = React.useState("");
  const [instagram, setInstagram] = React.useState("");
  const [twitter, setTwitter] = React.useState("");
  const [facebook, setFacebook] = React.useState("");
  const [magtappShorts, setMagtappShorts] = React.useState("");
  const [googleMapsLink, setGoogleMapsLink] = React.useState("");
  const [locationName, setLocationName] = React.useState("");
  const [userImage, setUserImage] = React.useState("");
  const [userCover, setUserCover] = React.useState("");
  const [image, setImage] = React.useState(null);
  const [cover, setCover] = React.useState(null);
  const [newError, setNewError] = useState("");
  const [showError, setShowError] = useState("");

  const { handleSubmit, errors, control } = useForm();

  const {
    handleSubmit: handleSubmit2,
    errors: errors2,
    control: control2,
  } = useForm({
    mode: "onBlur",
  });

  const HandleFileInput = (event) => {
    event.preventDefault();
    setImage(event.target.files[0]);
  };

  const HandleFileInputCover = (event) => {
    event.preventDefault();
    setCover(event.target.files[0]);
  };

  useEffect(() => {
    if (!props.auth.isAdmin) {
      props.history.push("/admin/Dashboard");
    }
    props.clearCategories();
    props.clearMybooks();
    props.clearSubbooks();
    props.clearSubcategories();
    fetchData()
  }, [props.auth]);

  const fetchData = async() =>{
    await axios
      .post(`/api/get-info-of-user`, { userId: props.match.params.userId })
      .then((res) => {
        if (res.data.success) {
          setShowError("");
          setName(res.data.name);
          setEmail(res.data.email);
          setUserName(res.data.username);
          setDescription(res.data.description);
          setWebsite(res.data.website);
          setYoutube(res.data.youtube);
          setInstagram(res.data.instagram);
          setTwitter(res.data.twitter);
          setFacebook(res.data.facebook);
          setMagtappShorts(res.data.magtappShorts);
          setGoogleMapsLink(res.data.googleMapsLink);
          setLocationName(res.data.locationName);
          setUserImage(res.data.image);
          setUserCover(res.data.cover);
          setSuccess(true);
          setShowError("");
        } else {
          setShowError("Something went wrong when getting user");
        }
      });
  }

  useEffect(() => {
    setError(props.errors);
  }, [props.errors]);

  const onSubmit = async (item) => {
    console.log("here", item);
    item["userId"] = props.match.params.userId;
    if (userImage !== "" && userImage !== undefined) {
      item["image"] = userImage;
    }
    if (userCover !== "" && userCover !== undefined) {
      item["cover"] = userCover;
    }
    console.log("here", item);
    await axios.post(`/api/auth/admin-update-user`, item).then((res) => {
      console.log(res);
      if (res.data.success) {
        window.location.reload();
      } else {
        setNewError(res.data.error);
      }
    });
  };

  const handleEditCover = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("cover", e.target.files[0]);
    formData.append("username", username);
    if (e.target.files[0] !== null) {
      await axios
        .post("/api/user/profile-pic", formData)
        .then((res) => {
          if (res.data.cover !== "" && res.data.cover !== undefined) {
            setUserCover(res.data.cover);
          }
        })
        .catch((err) => {
          setError(err);
        });
    }
  };

  const handleEditImage = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("image", e.target.files[0]);
    formData.append("username", username);
    if (e.target.files[0] !== null) {
      await axios
        .post("/api/user/profile-pic", formData)
        .then((res) => {
          if (res.data.image !== "" && res.data.image !== undefined) {
            setUserImage(res.data.image);
          }
        })
        .catch((err) => {
          setError(err);
        });
    }
  };

  const optionalForm = () => {
    return (
      <>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="website"
              id="website"
              name="website"
              value={website}
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="youtube"
              id="youtube"
              name="youtube"
              value={youtube}
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="instagram"
              id="instagram"
              name="instagram"
              value={instagram}
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="twitter"
              id="twitter"
              value={twitter}
              name="twitter"
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="facebook"
              id="facebook"
              name="facebook"
              value={facebook}
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="magtappShorts"
              id="magtappShorts"
              name="magtappShorts"
              value={magtappShorts}
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="googleMapsLink"
              id="googleMapsLink"
              name="googleMapsLink"
              value={googleMapsLink}
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="locationName"
              id="locationName"
              name="locationName"
              value={locationName}
              control={control}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomInput
              labelText="description"
              id="description"
              name="description"
              control={control}
              value={description}
              formControlProps={{
                fullWidth: true,
              }}
              required={false}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: "15px" }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <InputLabel
                  style={{
                    color: "#AAAAAA",
                    marginTop: "15px",
                    marginBottom: "-25px",
                  }}
                >
                  Upload Profile Picture
                </InputLabel>
                <FileInput
                  id="image-file"
                  type="file"
                  required={false}
                  name="ImageFile"
                  onChange={handleEditImage}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: "15px" }}>
            <InputLabel
              style={{
                color: "#AAAAAA",
                marginTop: "15px",
                marginBottom: "-25px",
              }}
            >
              Upload Cover Picture
            </InputLabel>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <FileInput
                  id="cover-image-file"
                  type="file"
                  required={false}
                  name="CoverImageFile"
                  onChange={handleEditCover}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <div>
              {userImage !== "" && (
                <img src={userImage} width="150" height="150" />
              )}
            </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <div>
              {userCover !== "" && (
                <img src={userCover} width="auto" height="150" />
              )}
            </div>
          </GridItem>
        </GridContainer>
      </>
    );
  };

  const classes = useStyles();
  return (
    <div>
      {success === true ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <form
              key={1}
              className={classes.form}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Card>
                <CardHeader color="primary">
                  <h2 className={classes.cardTitleWhite}>
                    Edit {username}'s Profile
                  </h2>
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="Name"
                        id="name"
                        name="name"
                        value={name}
                        control={control}
                        formControlProps={{
                          fullWidth: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  {optionalForm()}
                </CardBody>
                <CardFooter>
                  <Button type="submit" color="primary">
                    UPDATE
                  </Button>
                  {error.message && (
                    <Typography
                      color="textSecondary"
                      variant="subtitle2"
                      gutterBottom
                    >
                      {error.message}
                    </Typography>
                  )}
                  {newError !== "" && newError !== undefined && (
                    <Typography
                      color="textSecondary"
                      variant="subtitle2"
                      gutterBottom
                    >
                      {newError}
                    </Typography>
                  )}
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      ) : showError !== undefined || showError !== "" ? (
        showError
      ) : (
        "Loading please wait"
      )}
    </div>
  );
};
EditUserAdmin.propTypes = {
  registerUser: propTypes.func.isRequired,
  changePassword: propTypes.func.isRequired,
  auth: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(
  mapStateToProps,
  {
    registerUser,
    changePassword,
    clearMybooks,
    clearCategories,
    clearSubcategories,
    clearSubbooks,
  }
)(EditUserAdmin);
